var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-btn", {
        attrs: {
          flat: "",
          size: "12px",
          color: "orange",
          icon: "chevron_right",
        },
        on: {
          click: function ($event) {
            return _vm.rowClickDetail(_vm.props.row, _vm.props.rowIndex)
          },
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "q-card-section",
                { staticClass: "row" },
                [
                  _vm._t("customArea", null, {
                    props: _vm.props,
                    col: _vm.col,
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }